export interface Payload {
  $schema: string;
  questionnaire: Questionnaire;
}

export interface Translation {
  id?: string;
  [locale: string]: string;
}

export interface Questionnaire {
  id: string;
  status: string;
  revision: number;
  softwareVersion: string;
  tenantId: string|number;
  meta: Questionnaire.Meta;
  createdAt?: string;
  updatedAt?: string;
  questions: Questionnaire.Container[];
  accumulated?: Questionnaire.Accumulated;
}

export namespace Questionnaire {
  export interface Accumulated {
    index: Questionnaire.Accumulated.QuestionnaireIndex;
    pathHashes: Questionnaire.Accumulated.QuestionnairePathHashMap;
    questionPathHashes: Questionnaire.Accumulated.QuestionnaireQuestionPathHashMap;
    conditionPathHashes: Questionnaire.Accumulated.ConditionToContainerPathHashMap;
    initialNumberOfMandatoryQuestions: number;
    initialNumberOfQuestions: number;
  }

  export namespace Accumulated {
    export interface QuestionnairePathHashMap {
      [pathHash: string]: Questionnaire.Container;
    }

    export interface QuestionnaireQuestionPathHashMap {
      [pathHash: string]: {
        isOnPageNumber: number;
        isMandatory: boolean;
      };
    }

    export interface ConditionToContainerPathHashMap {
      [pathHash: string]: string[];
    }

    export interface QuestionnaireIndex {
      pages: QuestionnaireIndex.Page[];
    }

    export namespace QuestionnaireIndex {
      export interface Page {
        title: Translation;
        pageNumber: number;
        questions: Question[];
        expanded?: boolean;
      }

      export interface Question {
        pathHash: string;
        title: Translation;
        visible: boolean;
      }
    }
  }

  export interface Meta {
    author: string;
    title: Translation;
    type: string;
    description: Translation;
    image?: Translation;
    asset?: {
      image?: Container.Asset,
      video?: Container.Asset,
      audio?: Container.Asset
    };
    options?: {
      [prop: string]: any,
      pdf?: {
        generateInternal?: boolean
        forms?: string[]
      },
      gdt?: {
        addQuestionnaireTitle?: boolean
        addScoring?: boolean
      },
      fill?: {
        forceMandatory?: boolean
        skipList?: boolean
      },
      mail?: {
        filler: {
          internalPdf?: boolean,
          filledPdf?: boolean
        },
        user: {
          internalPdf?: boolean,
          filledPdf?: boolean
        }
      },
      excel?: {
        omitPatientData?: false
      },
      variables?: Options.Variables;
      variablesMapping?: Options.VariablesMapping[];
    };
    tags?: string[];
  }

  export interface Conditions {
    show?: string | string[];
    mark?: string;
    expression?: string;
  }

  export interface Description {
    type: string;
    text: Translation;
  }

  export namespace Options {
    export interface Variables {
      [scope: string]: Variables.Scope;
    }

    export interface VariablesMapping {
      source: {
        scope: string,
        variable: string
      };
      target: {
        scope: string,
        variable: string
      };
    }

    export namespace Variables {
      export interface Scope {
        [variableName: string]: {
          [pathHash: string]: any;
        };
      }

      export namespace Scope {
        export interface Flipped {
          [pathHash: string]: string;
        }
      }
    }

    export interface NetDiagramData {
      title: Translation;
      entries: NetDiagramEntry[];
    }

    export interface NetDiagramEntry {
      label: Translation;
      variable?: string;
      value?: number;
    }
  }

  export interface Container {
    id: string;
    packedId?: string;
    pathHash?: string;
    parentHash?: string;
    path?: string;
    title?: Translation;
    appendix?: Translation;
    description?: Description;
    format?: string;
    extra?: string;
    undeletable?: boolean;
    dataType?: string;
    displayType?: string;
    omitContainer?: boolean;
    assets?: Container.Assets;
    options?: Container.Options;
    elements?: Container[];
    conditions?: Conditions;
  }

  export namespace Container {
    export interface Options {
      active?: boolean;
      mandatory?: boolean;
      min?: number;
      max?: number;
      mark?: boolean;
      hideInPdf?: boolean;
      hideInApp?: boolean;
      hideInXls?: boolean;
      steps?: number;
      unit?: Translation;
      fullDateTime?: boolean;
      fullDate?: boolean;
      monthDate?: boolean;
      yearDate?: boolean;
      multipleAnswers?: boolean;
      invertAxes?: boolean;
      drawColor?: string;
      bgColor?: string;
      bgImage?: Translation;
      height?: number;
      drawScale?: boolean;
      allowUpload?: boolean;
      validation?: Validation;
      netDiagram?: Options.NetDiagramData[];
      multiline?: boolean;
      html?: boolean;
    }

    export interface Validation {
      regex?: string;
      mod?: string;
      hint?: Translation;
    }

    export interface Asset {
      filename: Translation;
      title: Translation;
      options?: Asset.Options;
    }

    export interface Assets {
      videos?: Asset[];
      images?: Asset[];
      audio?: Asset[];
    }

    export namespace Asset {
      export interface Options {
        croppedImage?: Translation;
        cropperPosition?: {x1: number, y1: number, x2: number, y2: number};
        previewImage?: Translation;
        allowFullscreen?: boolean;
        allowControls?: boolean;
        autoPlay?: boolean;
      }
    }

    export class DisplayType {
      static GROUP = 'group';
      static TEXT = 'text';
      static RADIO = 'radio';
      static CHECKBOX = 'checkbox';
      static PDF = 'pdf';
    }

    export class DataType {
      STRING: 'string';
      BOOLEAN: 'boolean';
      NUMBER: 'number';
      DATE: 'date';
    }

    export class Format {
      static PAGE_BREAK = 'pageBreak';
      static SINGLE_CHOICE = 'singleChoice';
      static MULTIPLE_CHOICE = 'multipleChoice';
      static MATRIX = 'matrix';
      static NUMERIC = 'numeric';
      static TEXT = 'text';
      static DATE = 'date';
      static INFO = 'info';
      static CANVAS = 'canvas';
      static PICTURE = 'picture';
      static SIGNATURE = 'signature';
      static NET_DIAGRAM = 'netDiagram';

      static YES_NO_MISC = 'singleChoice#yesNoMisc';
      static YES_NO = 'singleChoice#yesNo';
      static SCALE = 'matrix#scale';
      static TEXT_MULTILINE = 'text#multiline';
    }
  }
}
export interface Submission {
  _id: string;
  id?: string;
  uid?: string;
  revision?: number;
  swVersion?: string;
  bucketId?: string;
  requestId?: string;
  clientId?: string;
  surveyId?: string;
  client: any;
  questionnaire: Questionnaire;
  answers: {[answerPatHash: string]: any};
  variables: {
    scoring: {[varName: string]: number}
    inline: {[varName: string]: string | boolean | number};
    container: {[varName: string]: string | boolean | number};
  };
  visibilities: {[patHash: string]: boolean};
  signDocuments: {
    [documentId: string]: {
      documentId: string,
      documentName: string,
      sourceFileName: string,
      targetFileName: string,
      sourceType: string,
      targetType: string,
      processed: boolean
    }
  };
  uploadLinks?: any;
  exports?: any;
  error?: {
    message: string,
    stack: string
  };
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Client {
  uid?: string;
  customerNr?: string | number;
  caseNr?: string | number;
  gender?: string;
  lastName?: string;
  firstName?: string;
  address?: string;
  status?: string;
  birthDate?: string;
  location?: string;
  createdAt?: string;
  updatedAt?: string;
  anonymous?: boolean;
  pseudonymous?: boolean;
  pseudoId?: string;
  questionnaires?: Questionnaire[];
}

export namespace Workflow {
  export interface Stage {
    name?: Translation;
    description?: Translation;
    questionnaires: string[];
    generateExports: boolean;
    generateFormPDFs: boolean;
    prefill?: boolean;
  }
}

export interface Workflow {
  _id: string;
  id?: string;
  uid?: string;
  name: Translation;
  description?: Translation;
  stages?: Workflow.Stage[];
  updatedAt?: Date;
  createdAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export namespace Survey {
  export enum Status {
    created = 'created',
    assigned = 'assigned',
    submitted = 'submitted',
    signed = 'signed',
    unknown = 'unknown'
  }
}

export namespace WorkflowInstance {
  export enum Status {
    created = 'created',
    assigned = 'assigned',
    submitted = 'submitted',
    canceled = 'canceled',
  }
}

export interface WorkflowInstance {
  _id: string;
  id?: string;
  uid?: string;
  clientId: string;
  client?: Client;
  locale?: string;
  validUntil?: Date;
  workflowId?: string;
  workflow?: Workflow;
  surveys?: Survey[];
  status: WorkflowInstance.Status;
  stage?: number;
  surveyId: string;
  updatedAt?: Date;
  createdAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export interface Survey {
  _id: string;
  id?: string;
  uid?: string;
  clientId: string;
  appDataId?: string;
  appDataLink?: string;
  locale?: string;
  client: Client;
  questionnaires: Questionnaire[];
  submissions: Submission[];
  additionalData?: any;
  notificationApiUri?: string;
  returnUri?: string;
  validUntil?: Date;
  signing: boolean;
  allowMail?: boolean;
  status: Survey.Status;
  createdBy?: string;
  updatedAt?: string;
  createdAt?: string;
}
