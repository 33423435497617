/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {},
  CRUD: {
    WORKFLOW: 'Workflow',
    STAGES: 'Schritte',
    STAGE: 'Schritt',
    STAGE_NAME: 'Name',
    STAGE_DESCRIPTION: 'Beschreibung',
    STAGE_QUESTIONNAIRES: 'Fragebögen',
    ENTER_WORKFLOW_NAME: 'Workflow-Name eingeben',
    ENTER_WORKFLOW_DESCRIPTION: 'Workflow-Beschreibung eingeben',
    ENTER_STAGE_NAME: 'Schrittnamen eingeben',
    ENTER_STAGE_DESCRIPTION: 'Schrittbeschreibung eingeben',
    ADD_STAGE: 'Schritt hinzufügen',
    DELETE_STAGE: 'Schritt löschen',
    CONFIRM_DELETE_STAGE: 'Möchten Sie diesen Schritt wirklich löschen?',
    SAVE_WORKFLOW: 'Speichern',
    SUCCESSFULLY_SAVED_WORKFLOW: 'Workflow erfolgreich gespeichert!',
    ERROR_SAVING_WORKFLOW: 'Fehler beim Speichern des Workflows!',
    ERROR_LOADING_WORKFLOW: 'Fehler beim Laden des Workflows!',
    CONFIRM_DELETE_WORKFLOW: 'Möchten Sie diesen Workflow wirklich löschen?',
    FOUND_NO_ENTRY_BY_ID: 'Kein Eintrag gefunden mit der ID',

    DELETE_WORKFLOW: 'löschen',
    CLOSE_WINDOW: 'Fenster schließen',
    GENERATE_EXPORTS: 'Export Formate generieren',
    GENERATE_FORM_PDFS: 'PDF Formulare generieren',
    PREFILL: 'Vorausfüllen',
  },
  GRID: {
    USER: 'Benutzer',
    ID: 'Id',
    SEARCH: 'Suche',
    FOUND_NO_ENTRY_BY_ID: 'Kein Eintrag gefunden mit der ID',
    CONFIRM_DELETE_WORKFLOW: 'Möchten Sie diesen Workflow wirklich löschen?',
    SUCCESSFULLY_DELETED_WORKFLOW: 'Workflow erfolgreich gelöscht!',
    ERROR_DELETING_WORKFLOW: 'Fehler beim Löschen des Workflows!',
    WORKFLOW: 'Workflow',
    PATIENT: 'Patient',
    STATUS: 'Status',
    STATUS_CREATED: 'Erstellt / In Bearbeitung',
    STATUS_ASSIGNED: 'In Bearbeitung',
    STATUS_SUBMITTED: 'Abgeschlossen',
    STATUS_SIGNED: 'Abgeschlossen / Unterschrieben',
    STATUS_CANCELED: 'Abgebrochen',
    STATUS_UNKNOWN: 'Unbekannt',
    STATUS_EXPIRED: 'Abgelaufen',
    WORKFLOW_INSTANCES: 'Workflow-Instanzen',
    PATIENT_ID: 'PatNr.',
    CASE_NR: 'Fall-Nr.',
    ANONYMOUS: 'Anonym',
    PSEUDONYMOUS: 'Pseudonym',
    MR: 'Herr',
    MS: 'Frau',
    CONTINUE_WORKFLOW: 'Workflow fortsetzen',
    SUBMISSIONS: 'Abgaben',
    ASSETS: 'Dateien',
    COPY_LINK_SUCCESS: 'Link erfolgreich kopiert!',
    COPY_LINK_ERROR: 'Fehler beim Kopieren des Links!',
    COPY_LINK_TO_CONTINUE: 'Link zum Fortsetzen kopieren',
    SHOW_QR_CODE_TO_CONTINUE: 'QR Code zum Fortsetzen anzeigen',
    ERROR_LOADING_QR_CODE: 'Beim Laden des QR Codes ist ein Fehler aufgetreten!',
    QUESTIONNAIRES: 'Fragebögen',



    ERROR_MISSING_MANDATORY_CONFIG: 'Die Konfiguration enthält keine API URL zum Abrufen von Workflows!',
    ERROR_LOADING_WORKFLOWS: 'Beim Laden der Workflows ist ein Fehler aufgetreten!',
    CONFIG_ERROR: 'Konfigurationsfehler',
    WORKFLOWS: 'Workflows',
    NAME: 'Name',
    DESCRIPTION: 'Beschreibung',
    EDIT: 'Bearbeiten',
    DELETE: 'Löschen',
    STAGES: 'Schritte',
    GENERATE_EXPORTS: 'Export Formate generieren',
    GENERATE_FORM_PDFS: 'PDF Formulare generieren',
    PREFILL: 'Vorausfüllen',
    STAGE: 'Schritt',
    OPTIONS: 'Optionen',
    CREATED_AT: 'Erstellt am',
    UPDATED_AT: 'Bearbeitet am',
    ENTRIES: 'Einträge',
  }
};

